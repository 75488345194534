import { AccessTypes, AvailableModules, modules } from '@didomi/authorization-metadata';
import { useCustomFeature, useFeatureFlag } from '@didomi/utility-react';
import { AccessModuleBlock } from '@types';

const accessTextPerAccessType = {
  [AccessTypes.Editor]: 'Editor',
  [AccessTypes.Viewer]: 'Viewer',
};

const accessLevelPerAccessType = {
  [AccessTypes.Editor]: 2,
  [AccessTypes.Viewer]: 1,
};

export const useAccessModules = () => {
  const [hasCmp] = useFeatureFlag('consent_management_platform');
  const [hasPmp] = useFeatureFlag('preference_management_platform');
  const [hasDsar] = useFeatureFlag('dsar');
  const [hasVersionsAndProofs] = useFeatureFlag('versions_and_proofs');
  const [hasPrivacyCenter] = useCustomFeature('privacy-center');
  const [hasServerSide] = useFeatureFlag('server_side');
  const [hasIntegrations] = useFeatureFlag('integrations_v2');
  const [hasBatchExports] = useFeatureFlag('batch_exports');
  const [hasBatchExportsAnalytics] = useFeatureFlag('batch_exports_analytics');
  const hasMarketPlace = hasIntegrations || hasBatchExports || hasBatchExportsAnalytics;

  // Get select dropdown options for access module
  // E.g.: getModuleAccessOptions('cmp') => [{ label: 'Editor', value: 'cmp_editor', accessLevel: 2 }, { label: 'Viewer', value: 'cmp_viewer', accessLevel: 1 }]
  const getModuleAccessOptions = (module: AvailableModules) => {
    // E.g.: { viewer: 'cmp_viewer', editor: 'cmp_editor' }
    const policyMapping = modules[module].accessTypeToPolicyMapping;

    return Object.entries(policyMapping) // E.g.: [['viewer', 'cmp_viewer'], ['editor', 'cmp_editor']]
      .map(([accessType, policyId]) => ({
        label: accessTextPerAccessType[accessType],
        value: policyId,
        accessLevel: accessLevelPerAccessType[accessType],
      }))
      .sort((a, b) => b.accessLevel - a.accessLevel);
  };

  const accessModulesBlocks: AccessModuleBlock[] = [
    {
      id: AvailableModules.OrganizationSettings,
      displayedName: 'Organization Settings',
      illustration: 'org-settings-module',
      accessOptions: getModuleAccessOptions(AvailableModules.OrganizationSettings),
      isVisible: true,
      tooltipText:
        'Manage access to the Private API key page and the Single Sign-on (SSO) premium feature',
    },
    {
      id: AvailableModules.UsersAndRoles,
      displayedName: 'Users & Roles',
      illustration: 'users-and-roles-module',
      accessOptions: getModuleAccessOptions(AvailableModules.UsersAndRoles),
      isVisible: true,
    },
    {
      id: AvailableModules.DataManager,
      displayedName: 'Data Manager',
      illustration: 'data-manager-module',
      accessOptions: getModuleAccessOptions(AvailableModules.DataManager),
      isVisible: hasCmp || hasPmp,
    },
    {
      id: AvailableModules.CMP,
      displayedName: 'Consent Notices',
      illustration: 'cmp-module',
      accessOptions: getModuleAccessOptions(AvailableModules.CMP),
      isVisible: hasCmp,
    },
    {
      id: AvailableModules.PMPWidgets,
      displayedName: 'PMP Widgets',
      illustration: 'pmp-widgets-module',
      accessOptions: getModuleAccessOptions(AvailableModules.PMPWidgets),
      isVisible: hasPmp,
      tooltipText: 'Manage access to the PMP Widgets tab (Preference Management)',
    },

    {
      id: AvailableModules.DSARWidgets,
      displayedName: 'Privacy request widgets',
      illustration: 'dsar-widgets-module',
      accessOptions: getModuleAccessOptions(AvailableModules.DSARWidgets),
      isVisible: hasDsar,
      tooltipText: 'Manage access to the Widgets tab (Privacy request module)',
    },

    {
      id: AvailableModules.UserRequests,
      displayedName: 'User Requests',
      illustration: 'user-requests-module',
      accessOptions: getModuleAccessOptions(AvailableModules.UserRequests),
      isVisible: hasDsar,
      tooltipText: 'Manage access to the User Requests tab (Privacy request module)',
    },

    {
      id: AvailableModules.ConfigurationTrees,
      displayedName: 'Configuration Tree',
      illustration: 'configuration-trees-module',
      accessOptions: getModuleAccessOptions(AvailableModules.ConfigurationTrees),
      isVisible: hasPmp,
      tooltipText: 'Manage access to the Configuration Tree tab (Preference Management)',
    },
    {
      id: AvailableModules.Analytics,
      displayedName: 'Analytics',
      illustration: 'analytics-module',
      accessOptions: getModuleAccessOptions(AvailableModules.Analytics),
      isVisible: hasCmp,
    },
    {
      id: AvailableModules.NoticeVersions,
      displayedName: 'Notice Versions',
      illustration: 'notice-versions-module',
      accessOptions: getModuleAccessOptions(AvailableModules.NoticeVersions),
      isVisible: hasVersionsAndProofs,
      tooltipText: 'Manage access to the Notice Versions tab (Versions & Proofs)',
    },
    {
      id: AvailableModules.ConsentProofReports,
      displayedName: 'Consent Proof Reports',
      illustration: 'consent-proofs-module',
      accessOptions: getModuleAccessOptions(AvailableModules.ConsentProofReports),
      isVisible: hasVersionsAndProofs,
      tooltipText: 'Manage access to the Consent Proof Reports tab (Versions & Proofs)',
    },
    {
      id: AvailableModules.ComplianceReport,
      displayedName: 'Compliance Report',
      illustration: 'compliance-report-module',
      accessOptions: getModuleAccessOptions(AvailableModules.ComplianceReport),
      isVisible: hasCmp,
    },
    {
      id: AvailableModules.PrivacyCenter,
      displayedName: 'Privacy Center',
      illustration: 'privacy-center-module',
      accessOptions: getModuleAccessOptions(AvailableModules.PrivacyCenter),
      isVisible: hasPrivacyCenter,
    },
    {
      id: AvailableModules.MarketplaceManage,
      displayedName: 'Marketplace Manage',
      illustration: 'marketplace-manage-module',
      accessOptions: getModuleAccessOptions(AvailableModules.MarketplaceManage),
      isVisible: hasMarketPlace,
      tooltipText: 'Manage access to the Marketplace Manage section',
    },
    {
      id: AvailableModules.ServerSide,
      displayedName: 'Server Side',
      illustration: 'serverside',
      accessOptions: getModuleAccessOptions(AvailableModules.ServerSide),
      isVisible: hasServerSide,
      tooltipText: 'Manage access to the Server Side tab',
    },
  ].filter(({ isVisible }) => isVisible);

  return {
    accessModulesBlocks,
  };
};
